import React from "react"
import diagonalStyles from "./diagonal_banner.module.css"

export default function Subheader(props){
    //NEED:
    //props.image
    //props.content
    return(
        <div className={diagonalStyles.diagonal_subheader_wrapper }>
            <div className={diagonalStyles.subheader_topfade}></div>
            <div className={diagonalStyles.subheader_darken}></div>
            <img src={props.image} className={diagonalStyles.subheader_img} alt=""/>
            <img src="https://admhotels.com/images/gold_white_bg.jpg" className={diagonalStyles.subheader_img2} alt=""/>
            <div className={diagonalStyles.subheader_h2_div}>
                <div>
                    {props.children}
                    <svg
                    style={{transform:'rotate(90deg)',margin:0}}
                    width={24}
                    viewBox="0 0 266 438"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                    d="m258.476 235.971-194.344 194.343c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901l154.021-154.746-154.021-154.745c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0l194.343 194.343c9.373 9.372 9.373 24.568.001 33.941z"
                    fill={"#000"}
                    />
                </svg>    
                </div>
                
            </div>
            <div className={diagonalStyles.subheader_bottomfade}></div>
        
        </div>

    )
} 