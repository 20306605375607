import React from "react"
import Layout from "../components/layout"
import indexStyles from "./index.module.css"
import Content_surround from "../components/content_surround"
import Heading_banner from "../components/heading_banner"
import Subheader from "../components/subheader"
import Diagonal_banner from "../components/banners/diagonal_banner"
import Parallax from "../components/parallax"
//import {AnchorLink} from "gatsby-plugin-anchor-links" 
import {Link} from "gatsby"

export default function Home(props) {
  return (
    <Layout location={props.location.pathname}>
      <div className={indexStyles.index_wrapper}> 

        <div style={{padding:'16px',fontSize:'24px',border:'4px dashed #16a085',margin:'8px'}}>
          <p style={{padding:'4px',backgroundColor:'#2ecc71',fontSize:'36px',color:'black', textDecoration:'underline'}}><b>Latest Updates as of June 6, 2024:</b></p>
          <p>- ADM Hotels and Resorts Group, LLC(ADM GROUP) through the Joint Venture &amp;
              Equity Partnership with foreign investors is Developing LUXURY RITZ-CARLTON by
              Marriott(proposed), a Luxury Hotel &amp; Destination Golf Resort in South Bend,
              Indiana(USA). Full package of financing for this Development, including other projects by ADM GROUP under consideration, has
              been secured and 100% of funds are available as required, estimated amount of ONE BILLION
              US Dollars($1,000,000,000) by the Joint Venture and Equity Partnership.
              Construction will begin Fall 2024. ADM GROUP is not seeking any
              further investment or funding.</p>
          {/*<p>- Ritz Carlton (proposed) Luxury Hotel & Destination Golf Resort South Bend (Development) has funds to begin construction soon. All funds, full budget, total project cost is available of One Billion US Dollars ($1,000,000,000) investment by Joint Venture and Equity Partnership. ADM Group is not seeking any other investment for this project.</p>*/}
          <p>- ADM GROUP has been selected to develop its projects through a highly reputable,
          professional, and qualified team including Project Manager, Program Manager,
          and top-ranking Design &amp; Architecture Companies.</p>
          {/*<p>- ADM Group has selected to develop this resort with highly reputable and qualified Project Manager and Program Manager for developing South Bend project. </p>
          <p>- Office will be open July 24, 2023.</p>*/}
          {/*<p>- Full project/development budget of One Billion US Dollars ($1,000,000,000) under investment and available by Joint Venture and Equity Partnership.</p>*/}
          {/*<p>- Full investment of $1Billion ($1,000,000,000) will be available by JV & Equity partners by June 30th, 2022.</p>*/}


          {/*<p>- ADM GROUP will hold an Executives and kick-off meeting on or before May 10th, 2024 in South Bend:</p>
            <ul style={{marginLeft:'16px'}} className={indexStyles.updates_positions}>
              <li>(i) All ADM GROUP Bank Accounts and Sub Accounts needed for Managing the South
              Bend project will be opened by the administration.</li>
              <li>(ii) All Executive agreements and contracts will be executed with ADM
              GROUP.</li>
              <li>(iii) Sign-on bonuses will be released to all executives and other employees by the payroll company, Insperity, and accounting firm, CBIZ.</li>
              <li>(iv)A National level Law Firm is being engaged (under negotiations) to coordinate the legal requirements and laws.</li>
              <li>(v) The contract with Gensler for Architectural Design &amp; Engineering of
              South Bend Development will be signed during this executive meeting.</li>
              <li>(vi) Blackthorn development planned to begin construction after South Bend
              Redevelopment Commission (RDC) approval on or before August
              31st, 2024.</li>
        </ul>*/}
            <p>- ADM GROUP initial meeting to discuss planning and strategy will be held in South Bend from 12th-14th June and following members are participating:</p>
            <ul style={{marginLeft:'16px'}} className={indexStyles.updates_positions}>
              <li>(i) Nausher Ahmad Sial, Principal and Chief Executive Officer.</li>
              <li>(ii) Mr Richard Bensinger, Chief Operating Officer.</li>
              <li>(iii) Chief Development Director and acting CFO.</li>
              <li>(iv) Chief Project Manager.</li>
              <li>(v) Mr Jerry Dominiack, Development Supervisor.</li>
              <li>(vi) Miss Shirley Collins, Executive Business Director.</li>
              <li>(vii) Randy Ziokaski, Chief Engineer & Maintenance Manager.</li>
              <li>(viii) Above mentioned will be included in Phase One OR Group One (hiring).Sign on bonus and payroll will be started from June14th,2024 through Insperity(payroll company).</li>
              <li>(ix) Hiring of second group of employees will be de published-on 14th June as well</li>
          </ul>

          <p>- The Development is in final review by executives, specialists and professionals of
            its team. Feasibility reports(drafts) for Blackthorn Development and hotel, golf
            and Residential/Condominiums and housing provided by PKF, REVPAR &amp; JHG are being
            reviewed by experts and professionals of hospitality industry.</p>

          {/*<p>- Ritz Carlton proposed feasibility report has been completed for Blackthorn location, and is currently under final review by industry experts and ADM Group management and leadership teams.</p>*/}
          <p>- The hiring phase of ADM GROUP executive office has been completed.</p>
          <ul style={{marginLeft:'16px'}} className={indexStyles.updates_positions}>
            <li><span style={{backgroundColor:'#b7f4d8',fontWeight:'normal'}}>Chief Operating Officer (COO) - <span style={{color:'red'}}>Filled</span></span></li>
            <li><span style={{backgroundColor:'#b7f4d8',fontWeight:'normal'}}>Chief Development Officer (CDO) - <span style={{color:'red'}}>Filled</span></span></li>
            <li><span style={{backgroundColor:'#b7f4d8',fontWeight:'normal'}}>Chief Financial Officer (CFO) - <span style={{color:'red'}}>Acting CFO Assigned</span></span></li>
            <li><span style={{backgroundColor:'#b7f4d8',fontWeight:'normal'}}>Project Manager (PM) - <span style={{color:'red'}}>Filled</span></span></li>
            <li><span style={{backgroundColor:'#b7f4d8',fontWeight:'normal'}}>Project Supervisor - <span style={{color:'red'}}>Filled</span></span></li>
          </ul>
          <p>- All remaining and required administrative staff and employees will be hired by the Executive and management team and will be filled through 3rd party (LinkedIn/HR). Visit our <a href="http://www.admhotels.com/careers">Careers Page</a> to apply.</p>
          <ul style={{marginLeft:'16px'}} className={indexStyles.updates_positions}>
            <li style={{fontWeight:'normal'}}>Chief Financial Officer (CFO)</li>
            <li style={{fontWeight:'normal'}}>Business and Development Director (BDD)</li>
            <li style={{fontWeight:'normal'}}>Financial Director & Controller, CPA</li>
            <li style={{fontWeight:'normal'}}>Financial Director & Analyst, CPA</li>
            <li style={{fontWeight:'normal'}}>Director of Government and Public Affairs</li>
            <li style={{fontWeight:'normal'}}>Executive Administrator</li>
            <li style={{fontWeight:'normal'}}>Executive Assistant</li>
            <li style={{fontWeight:'normal'}}>Executive Office Secretary</li>
            <li style={{fontWeight:'normal'}}>CTO, Web & Graphic Design - TBD</li>
            <li style={{fontWeight:'normal'}}>IT Assistant</li>
          </ul>
          {/*<p>- Contract with Gensler for Architectural Design & Hotel agreement will be signed July 26.</p>*/}
          {/*<p>- Blackthorn development planned to begin after South Bend Redevelopment Commission (RDC) approval on or before November 30, 2023.</p>*/}
        </div>

        <div className={indexStyles.below_header} id="learnmore">
          <div>
            <img src="https://admhotels.com/images/condo-880.jpg"/>
            <h2>High Class Condominiums</h2>
          </div>
          <div>
            <img src="https://admhotels.com/images/hotel1-600.jpg"/>
            <h2>World Class Hotel Luxury</h2>
          </div>
          <div>
            <img src="https://admhotels.com/images/golf2-600.jpg"/>  
            <h2>Golf Course To Be Designed By Jack Nicklaus</h2>
          </div>
          <div>
            <img src="https://admhotels.com/images/nd-600.jpg"/>
            <h2>Premier Destination</h2>
          </div>
          
        </div>

        <Diagonal_banner image="https://admhotels.com/images/hotel_02.jpg">
            <h2>Redefining Luxury</h2>
            <i class="fa fa-chevron-down"></i>
        </Diagonal_banner>
        <div className={indexStyles.content1}>
          
          <div className={indexStyles.content_div}>
            <h2>ADM Hotels Is Redefining Luxury</h2>
            <p>Our new luxury hotel and condominium development will be an International Destination resort at Blackthorn Development Park in South Bend, IN. Catering to Notre Dame Alumni and market.</p>
            <ul>
            <li>250 Guest Room Full-Service Luxury Hotel</li>
            <li>50,000 sqft Conference Center</li>
            <li>1,200 Space Parking Garage</li>
            <li>Indoor / Outdoor Pool</li>
            <li>Upscale Full-Service Dining</li>
            <li>Destination Sports Bar</li>
            <li>Limousine Service</li>
            </ul>
            <br/>
            <Link to="/blackthorn" className={indexStyles.learn_more_button}>>> Learn More About Blackthorn</Link>

          </div>
          <div className={indexStyles.inner_pics}>
            <div>
              <img src="https://admhotels.com/images/hotel_02.jpg"/>
              <h2>350 Guest Room Full-Service Luxury Hotel</h2>
            </div>
            <div>
              <img src="https://admhotels.com/images/conference-600.jpg"/>
              <h2>50,000 sqft Conference Center</h2>
            </div>
            <div>
              <img src="https://admhotels.com/images/pool-600.jpg"/>
              <h2>Indoor/ Outdoor Pool</h2>
            </div>
            <div>
              <img src="https://admhotels.com/images/chef-600.jpg"/>
              <h2>Upscale Full-Service Dining</h2>
            </div>
            <div>
              <img src="https://admhotels.com/images/bar-600.jpg"/>
              <h2>Destination Sports Bar</h2>
            </div>
            <div>
              <img src="https://admhotels.com/images/limo-600.jpg"/>
              <h2>Limousine Service</h2>
            </div>
            <div>
              <img src="https://admhotels.com/images/golf1-900.jpg"/>
              <h2>World-Class Golf Course</h2>
            </div>
            <div>
              <img src="https://admhotels.com/images/tennis-600.jpg"/>
              <h2>World-Class Tennis Facilities</h2>
            </div>
          </div>
        </div>

        <Diagonal_banner image="https://admhotels.com/images/adm_business_1.jpeg">
            <h2>Meet The Team</h2>
            <i class="fa fa-chevron-down"></i>
        </Diagonal_banner>
        

        
          <div className={indexStyles.content2}>
            <div className={indexStyles.dark_overlay}>
            <div className={indexStyles.content_div}>
              <h2>Meet the Team</h2>
              <p>We came to contribute to something that we're passionate about and proud of.</p>
              <p>At the heart of it all is our relationships - with our partners, investors, and colleagues.</p>
              <p>We form genuine, collaborative relationships that last.</p>
              <br/>
              <Link to="/about" className={indexStyles.learn_more_button}>>> Learn More About Our Team</Link>
            </div>

            <h2>Our Founders</h2>
            <div className={indexStyles.team_wrapper}>
              <div>
                <img className={indexStyles.bg} src="https://admhotels.com/images/golf1-900.jpg"/>
                <img className={indexStyles.member} src="https://admhotels.com/images/adm-main/headshots/mark-jacob-thomas-hs.jpg"/>
                <p>Mark Jacob Thomas</p>
                <p>Principal and Founding Member</p>
              </div>
              <div>
                <img className={indexStyles.bg} src="https://admhotels.com/images/condo-880.jpg"/>
                <img className={indexStyles.member} src="https://admhotels.com/images/2020/06/21/nausher-ahmad2.jpg"/>
                <p>Nausher Ahmad Sial</p>
                <p>Principal and Founding Member</p>
              </div>
            </div>


            {/*<h2>Our Team</h2>

            <div className={indexStyles.team_wrapper}>
              <div>
                <img className={indexStyles.bg} src="https://admhotels.com/images/luxury-hotel.jpg"/>
                <img className={indexStyles.member} src="https://admhotels.com/images/nick_gearhart.jpg"/>
                <p>Nick Gearhart</p>
                <p>Chief Technology Officer</p>
              </div>
              <div>
                <img className={indexStyles.bg} src="https://admhotels.com/images/luxury-hotel-3.jpg"/>
                <img className={indexStyles.member} src="https://admhotels.com/images/anonymous-woman.jpg"/>
                <p>Julie Standifer, CPA</p>
                <p>Director of Finance, CPA</p>
              </div>
              <div>
                <img className={indexStyles.bg} src="https://admhotels.com/images/luxury-hotel-2.jpg"/>
                <img className={indexStyles.member} src="https://admhotels.com/images/charlene-smith-3.jpg"/>
                <p>Charlene Smith</p>
                <p>Executive Administrator</p>
              </div>
            </div>*/}

            <h2>Our Partners</h2>
            <div className={indexStyles.partners_wrapper}>
              
            <a href="https://www.ritzcarlton.com/"><div>
                <img src="https://admhotels.com/images/adm-main/logos/ritz-carlton.png"/>
              </div></a>
              
              {/*<a href="https://cressy.com/"><div>
                <img src="https://admhotels.com/images/adm-main/logos/ngce-logo-200x200.png"/>
          </div></a>*/}

              {/*<a href="https://rsmus.com/"><div>
                <img src="https://admhotels.com/images/adm-main/logos/rsm-logo-200x200.png"/>
              </div></a>*/}

              <a href="https://www.hvs.com/"><div>
                <img src="https://admhotels.com/images/adm-main/logos/hvs-logo-200x200.png"/>
              </div></a>

              {/*<a href="https://www.walshgroup.com/"><div>
                <img src="https://admhotels.com/images/adm-main/logos/walsh-group-logo-200x200.png"/>
              </div></a>*/}

              {/*<a href="https://www.csoinc.net/"><div>
                <img src="https://admhotels.com/images/adm-main/logos/cso-architects-logo-200x200.png"/>
              </div></a>*/}

              {/*<a href="https://www.thegibsonedge.com/"><div>
                <img src="https://admhotels.com/images/adm-main/logos/gibson-insurance-logo-200x200.png"/>
              </div></a>*/}

              {/*<a href="https://www.crowe.com/"><div>
                <img src="https://admhotels.com/images/adm-main/logos/crowe-logo-200x200.png"/>
              </div></a>*/}

              <a href="https://www.insperity.com/"><div>
                <img src="https://admhotels.com/images/insperity-logo.svg"/>
              </div></a>

              {/*<a href="https://aaklaw.com/"><div>
                <img src="https://admhotels.com/images/adm-main/logos/aaklawlogo.png"/>
              </div></a>*/}

              <a href="https://hoffmanstrategygroup.com/"><div>
                <img src="https://admhotels.com/images/adm-main/logos/HSG-logo.png"/>
              </div></a>

              <a href="https://revparintl.com/"><div>
                <img src="https://admhotels.com/images/adm-main/logos/revparlogo.png"/>
              </div></a>

              <a href="https://www.pkf.com/"><div>
                <img src="https://admhotels.com/images/adm-main/logos/pkflogo.jpg"/>
              </div></a>

              <a href="https://www.thehortongroup.com/locations/insurance-south-bend-in/"><div>
                <img src="https://admhotels.com/images/adm-main/logos/logo-Horton.svg"/>
              </div></a>

              <a href="https://www.cbiz.com/about-us/locations/company-details/cbiz-mhm-llc---new-york-1"><div>
                <img style={{backgroundColor:'black'}} src="https://admhotels.com/images/adm-main/logos/newCbizLogo.png"/>
              </div></a>

              <a href="https://www.nicklaus.com/"><div>
                <img src="https://admhotels.com/images/adm-main/logos/nicklaus.jpg"/>
                <p style={{fontWeight:'bold', fontSize:'24px'}}>Jack Nicklaus</p>
                <p>Golf Designer and Architect</p>
              </div></a>

              <a href="https://www.landscapesunlimited.com/index.php"><div>
                <img src="https://admhotels.com/images/adm-main/logos/landscapes-unlimited.jpg"/><br></br>
                <p style={{fontWeight:'bold', fontSize:'24px'}}>Golf Course Construction Manager</p>
              </div></a>

              <a><div>
                <p style={{fontWeight:'bold',fontSize:'24px'}}>Jerry N. Slack & Associates, Inc</p><br></br>
                <p>Golf Course Architect and Designer</p>
              </div></a>
              

            </div>
          </div>


        </div>
        



        <Diagonal_banner image="https://admhotels.com/images/join_adm.jpg">
            <h2>Join Our Team</h2>
            <i class="fa fa-chevron-down"></i>
        </Diagonal_banner>



        <div className={indexStyles.content3}>
          <div className={indexStyles.dark_overlay3}>


            <div className={indexStyles.passion_block}>
              <div>
                <img src="https://admhotels.com/images/team_1.jpg"/>
                <p>Passion</p>
              </div>
              <div>
                <img src="https://admhotels.com/images/team_2.jpg"/>
                <p>Talent</p>
              </div>
              <div>
                <img src="https://admhotels.com/images/team_3.jpg"/>
                <p>Vision</p>
              </div>
            </div>

            <div className={indexStyles.content3_content}>
              <h2 className="text-center">Join Us at ADM Hotels</h2>
              <p>Interested in a career at ADM?</p>
              <p>At ADM Hotels and Resorts Development, our people are the core of our success. Reflecting our four values – commitment, honesty, trust and passion – we make a promise to everyone in the company, that we will work with them to make this the best career they will ever have.</p>
              <p>The foundation for our success depends on your wellbeing. So we made a commitment to make our workplace an environment where your emotional, physical and financial needs matter. An environment where each one of us feels like a valued member of a team. A place where we’re motivated to make a difference in our communities.</p>  
              <br/>
              <Link to="/careers" className={indexStyles.learn_more_button}>>> View Opportunities</Link>
              <br/><br/><br/>
              <div className={indexStyles.careers_img_wrapper}>
                <Link to="/careers" >
                  <img src="https://admhotels.com/images/join_our_team.jpg" />
                </Link>
                
              </div>

            </div>
            <h2 style={{color:'white'}}>Available Careers:</h2>
            <div className={indexStyles.jointeam}>
              
              <div className={indexStyles.available_careers}>
                  
                  <Link to="/careers">Business Development Officer <br/> (Learn More)</Link>
                  <Link to="/careers">Financial Director & Controller, CPA <br/> (Learn More)</Link>
                  <Link to="/careers">Financial Director & Analyst, CPA<br/> (Learn More)</Link>
                  <Link to="/careers">Director of Gov't & Public Affairs <br/> (Learn More)</Link>
                  <Link to="/careers">Executive Administrator <br/> (Learn More)</Link>
                  <Link to="/careers">Executive Assistant <br/> (Learn More)</Link>
                  <Link to="/careers">Executive Office Secretary <br/> (Learn More)</Link>
                  <Link to="/careers">IT Assistant<br/> (Learn More)</Link>

              </div>

            </div>




          </div>
        </div>




        <Diagonal_banner image="https://admhotels.com/images/business_contact.jpg">
            <h2>Contact Us</h2>
            <i class="fa fa-chevron-down"></i>
        </Diagonal_banner>



        <div className={indexStyles.content4}>
          <div className={indexStyles.darken}></div>
            <div className={indexStyles.contact_us}>
              <h2 style={{textDecoration:'underline'}}>Contact Information:</h2>
              <p>office: 1657 Commerce Dr Suite #16A, South Bend, IN 46628</p>
              <p>phone: 312-933-4642</p>
              <p>email: info@admhotels.com</p>

            </div>
        </div>



      </div>
    </Layout>



  ) 
}
